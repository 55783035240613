<template>
  <header>
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
    <Title/>
  </header>
  <body>
    <SideMenu/>
    <div id="content">
      <div v-for="photo in items"
           :key="photo.id">
           <img width="600" class="thumb" :src="thumbUrl(photo.filename)"> 
           <div class="imagemeta">
             <span>{{  photo.title }}</span>
             <br>
             <span>{{  photo.year }}</span>
             <br>
             <span>{{  photo.materials }}</span>
             <br>
             <span>{{  photo.size }}</span>
             <br>
             <span>{{  photo.t1 }}</span>
             <br>
           </div>
      </div>
    </div>
  </body>
</template>


<script>

import photos from '@/photos.json'
import SideMenu from '@/components/SideMenu.vue'
import Title from '@/components/Title.vue'

function getPhotos(photos, cat) {
  if (cat == "all") {
    return photos
  }

	return photos.filter( function(p) {
		if (p.category == cat) {
			return p
		}
	})
}

export default {

    name: 'Gallery',
    data() {
      return {
        items: getPhotos(photos, this.category),
      };
    },
    methods: {
      thumbUrl(filename) {
              return require(`../images/resized/${filename}`);
      },
    },
		props: {
			category: {
				type: String,
				default: "other"
			}
    },
    components: {
      SideMenu,
      Title
    }
};
</script>

<style>

#content {
  left: 180px;
  top: 150px;
  display: inline-block;
  position: relative;
}

.thumb {
  border: 1px solid #bbbbbb;
  padding: 4px;
}

.imagemeta {
  padding-bottom: 40px;
  padding-left: 7px;
  padding-top: 7px;
  min-width: 175px;
}

.imagemeta span {
  text-transform: lowercase;
  margin: 3px 0;
  display: inline-block;
  font-family:'FreeMono', monospace;
  font-size: 1em;
  font-weight: 100;
  color: #525050;
}

@media screen and (max-width: 1000px) {
  #content {
    min-width: 73%;
    left: 0;
    margin-left: 18px;
    width: auto;
    margin-right: 18px;
    top: 80px;
    background-color: white;
  }

  .thumb {
    width: auto;
    max-width: 100%;
  }
}


</style>


