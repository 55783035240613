<template>
  <div id="top-title">
    <a href="/index.html">Cara Clarke</a>
  </div>
</template>

<script>

export default {
  name: 'Title'

}
</script>

<style>

#top-title a {
  display: none;
}

@media screen and (max-width: 1000px) {
  #top-title a {
    display: block;
    color: #b8042e;
    font-weight: 300;
    font-size: 1.2em;
    position: fixed;
    top: 3%;
    margin-left: 25px;
    text-decoration: none;
  }

}
</style>
