<template>
  <link href="https://fonts.googleapis.com/css?family=Roboto|Poppins" rel="stylesheet">
  <meta name="viewport" content="width=device-width">
  <header>
    <div id="burger"
         :class="{ 'active' : isOpen }"
         @click.prevent="toggle">
      <slot>
        <button type="button" class="burger-button" title="Menu">
          <span class="burger-bar burger-bar--1"></span>
          <span class="burger-bar burger-bar--2"></span>
          <span class="burger-bar burger-bar--3"></span>
        </button>
      </slot>
    </div>
  </header>

  <body>
    <div id="menu" class="sidenav" :class="{ 'active' : isOpen }">
      <a id="title" href="/index.html">Cara Clarke</a>
      <a class="mylink" href="/portraits.html">Portraits</a>
      <a class="mylink" href="/figures.html">Figures</a>
      <a class="mylink" href="/landscape.html">Landscape</a>
      <a class="mylink" href="/still.html">Still Life</a>
      <a class="mylink" href="/pets.html">Pet Portraits</a>
      <a class="mylink" href="/contact.html">Contact</a>
    </div>
  </body>
</template>

<script>

export default {
  name: 'SideMenu',
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style>

html {
  display: table;
  margin: auto;
}

body {
  text-transform: uppercase;
  vertical-align: middle;
  display: table-cell;
  -webkit-text-size-adjust: 100%;
}

button {
    cursor: pointer;
}

/* remove blue outline */
button:focus {
    outline: 0;
}

header {
  background-color: white;
}

.burger-button {
    position: fixed;
    height: 30px;
    width: 50px;
    display: none;
    z-index: 60;
    border: 0;
    right: 0%;
    margin-right: 25px;
    top: 2%;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform .6s cubic-bezier(.165,.84,.44,1);
}

.burger-bar {
    background-color: #000;
    position: absolute;
    right: 2px;
    left: 20px;
    height: 1.8px;
    width: auto;

    margin-top: -1px;
    transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
}

.burger-bar--1 {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
}

.burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(.8);
}

.burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
}

.burger-bar--3 {
    transform: translateY(6px);
}

#burger.active .burger-button {
    transform: rotate(-180deg);
    transform: translate(0px);
}

#burger.active .burger-bar--1 {
    transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
    opacity: 0;
}

#burger.active .burger-bar--3 {
    transform: rotate(-45deg)
}

/* The side navigation menu */
.sidenav {
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  background-color: transparent; 
  overflow-x: hidden; /* Disable horizontal scroll */
  top: 80px;
  display: block;
  margin-left: -50px;
  min-width: 125px;
  max-width: 225px;
  width: auto;
}

/* The navigation menu links */
.sidenav a {
  text-decoration: none;
  font-size: 1em;
  padding-bottom: 12px;
  display: block;
  color: #525050;
  font-weight: 200;
}

#title {
  color: #b8042e;
  font-weight: 400;
  font-size: 28px;
  padding-bottom: 40px;
}

.mylink:hover {
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
	.burger-button {
		display: block;
  }

  #menu {
    display: block;
    transform: scaleY(0);
    right: 0%;
    margin-right: 10px;
    top: 0px;

    padding: 20px 5px 0px 25px;
    transition: 0.33s ease;
    background-color: white;
    transform-origin: top;
    a {
      transition: 0.25s ease;
      opacity: 0;
    }
  }

  #menu.active {
    transform: scaleY(1);
    a {
      opacity: 1;
    }

  }

  .sidenav {
    box-shadow: 2px 3px 3px black;
    width: 35%;
  }
  .sidenav a {
    font-size: 1em;
    text-transform: uppercase;
  }

  #menu #title {
    display: none;
  }

}
</style>


